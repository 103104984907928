* {
  box-sizing: border-box;
}

.users-content {
  border: 0px solid blue;

  //width:93%;
  //margin: 0 auto;
  >section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .title-section {

    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    .title{
    }
  }

  .search-section {
    //display: flex;
    //align-items: center;
    flex-direction: row;
    margin-bottom: 40px;
    ;

  }

  .table-section {
    margin-bottom: 40px;
    border: 1px solid gray;
  }

  .search-wrap {
    position: relative;

    .search-buttons-wrap {
      display: flex;
      flex-direction: row-reverse;
      width: 70px;
      justify-content: space-between;
      //goa-icon-button[icon="close"] {
        //visibility: hidden;
      //}
    }
  }
  .search-preview-wrap {
    border: 1px solid grey;
    position: absolute;
    z-index: 10000;
    background: #fff;
    height: auto;
    margin-bottom: 25px;
    border-radius: 6px;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
    overflow: auto;

  }
   
  .h3 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

  }

  .link-button {
    display: inline-flex;
    box-sizing: border-box;
    border-radius: 0.25rem;
    background-color: var(--goa-color-interactive-default);
    border: 2px solid var(--goa-color-interactive-default);
    box-sizing: border-box;
    cursor: pointer;
    font-family: var(--goa-font-family-sans);
    font-size: var(--goa-font-size-5);
    font-weight: 400;
    height: var(--button-height);
    letter-spacing: var(--goa-letter-spacing-button);
    line-height: 100%;
    white-space: nowrap;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    transition: transform 0.1s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    color: var(--goa-color-text-light) !important;
    height: var(--button-height-compact);
    font-size: var(--goa-font-size-4);
    text-decoration: none;
    padding: 4px 8px 8px 8px;
  }
}