@import "@abgov/web-components/index.css";

body {
  .MuiInputBase-input {
    padding: 10px 0px 10px 12px;
    background-color: #fff;
  }

  .MuiFormControl-root{
    background-color: #FFF;
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border: 2px solid var(--goa-color-interactive-error) !important;
    box-shadow: 0 0 0 1px var(--goa-color-interactive-error) !important;

  }

  .MuiOutlinedInput-input:disabled{
    background-color: #ccc  ;
  }

  .MuiTab-root {
    text-transform: none;
    font-size: 18px;
    font-weight: 400;
    color: black;
  }

  .MuiTab-root.Mui-selected {
    font-weight: 400;
    color: black;
}

  .date-error{
    .MuiOutlinedInput-notchedOutline{
      border: 2px solid var(--goa-color-interactive-error) !important;
      box-shadow: 0 0 0 1px var(--goa-color-interactive-error) !important;

    }
  }
}
