.userCtrl-content {
    width: 100%;

    >section {
        display: flex;
        direction: row;
        align-items: center;
        justify-content: space-between;

    }

    .userNameSec {

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .modal-content {
        height:400px;
        overflow: hidden;
        .search-section {

            margin-bottom: 40px;
            width: 100%;
            display: flex;
            //align-items: center;
            justify-content: space-between;

            .search-wrap {
                position: relative;
                width: calc(100% - 65px);
                padding-left: 2px;;
                .search-buttons-wrap {
                    display: flex;
                    flex-direction: row-reverse;
                    width: 70px;
                    justify-content: space-between;
                    //goa-icon-button[icon="close"] {
                    //visibility: hidden;
                    //}
                }
            }

            .search-preview-wrap {
                border: 1px solid grey;
                position: absolute;
                z-index: 10000;
                background: #fff;
                height: 160px;
                margin-bottom: 25px;
                border-radius: 6px;
                box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
                overflow: auto;

            }

        }
    }
}