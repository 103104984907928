.nav-button {
  margin-right: 16px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.dd{
  border-color: brown;
  border-width: 2px;
}
.ddd{
  border-color: blue;
  border-width: 2px;
}
.nav {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: larger;
  border-bottom: 4px solid rgba(0, 171, 209, 100);
}

.user-name {
  font-size: small;
}

ion-icon {
  height: initial !important;
}

.popover-wrapper {
  display: flex;
  align-items: center;
  padding: 16px 16px 0 0;
}

.email-wrapper {
  padding: 0 8px 8px 8px;
}

.popover-content {
    width: 200px;
}