.myToast{
    position :fixed;
    right:0;
    bottom:  0;
    box-shadow:  1px 4px 8px rgba(0, 0, 0, 0.5);
    margin-right: 100px;;
    margin-bottom: 100px;;
    z-index: 10000;
    .toastContent{
        display: flex;
        justify-content: space-between;
        align-items: center;
        //margin-bottom: -var(--goa-space-l);;
    }   
}
