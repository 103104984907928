.chips {
  margin-top: 4px;
  .chip {
    display: inline-block;
    margin-right: 4px;
    min-height: 40px;
  }
}


.middle-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #F1F1F1;
  padding: 8px;
  margin-bottom: 16px;
}

.note {
  font-size: 14px;
}

.save-buttons {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 16px;
}


