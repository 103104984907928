label {
  font-family: acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.label-wrapper {
  margin-bottom: 8px;
}

.required {
  font-family: acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ec040b;
}

.date-grid {
  width: 700px;
  display: grid;
  grid-template-areas: "a a";
  gap: 10px;
  grid-auto-rows: 100px;
}

.error {
  font-family: acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ec040b;
  display: flex;
}

.lead-button {
  display: inline-block;
  margin-right: 8px;
}

.save {
  display: flex;
  flex-direction: row-reverse;
  width: 700px;
  margin: 16px 0;
}  

.notification {
  display: flex;
  flex-direction: row-reverse;
  width: 700px;
}

.save-button {
  color: var(--goa-color-text-light);
  background-color: #0070c4;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 18px;
  margin-top: -1px;
  cursor: pointer;
  width:95px;
}
.save-button:disabled{
  background-color: #0070c4;
  opacity: 0.5;
  cursor: default;
}

.breadcrumbs {
  color: var(--goa-color-interactive-default);
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
  goa-icon {
    margin-right: 4px;
  }
}

h1 {
  // font-family: acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  font-size: 36px !important;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}

.page {
  padding-top: 16px;
  width: 700px;
  margin: auto;
}

.sub-text {
  font-family: acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #bbb;
  padding-left: 8px;  
  padding-top: 8px;
}

.access-ended {
  .sub-text {
    padding-left: 0;
    padding-top: 0;
    margin-top:-12px;
  }
}

.spinner {
  text-align: center;
}

.date-wrapper {
  display: flex;
  align-items: flex-start;
}

form {
  padding-top: 28px;
  .field {
    margin-bottom: 24px;
  }
}

.date-field {
  width: 200px;
  display: inline-block;
}