.table-wrapper {
  margin-bottom: 25px;
  border-radius: 6px;
  overflow: hidden;
  /* Clip any scrollbars that appear */
  width: 100%;
  ;

  table {
    //grid-template-columns:minmax($colMin, $colMax) minmax($colMin, $colMax) minmax($colMin, $colMax) minmax($colMin, $colMax) minmax($colMin, $colMax) minmax($colMin, $colMax) minmax($colMin, $colMax) minmax($colMin, $colMax) minmax($colMin, $colMax);
    //grid-template-columns: repeat(9, 1fr); // one fractin for each col
    //grid-template-columns: repeat(9,auto);
      width: 100%;
      display: grid;
      overflow: auto;
      thead,tbody,tr{
        display: contents;
      }
      th,td{
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        min-width: 100px;
      }
      th{
        position: relative;
      }
      td{
        border-top: 1px solid #ccc;
      }
      th span,
      td span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }
  .resize-handle {
    display: block;
    position: absolute;
    cursor: col-resize;
    width: 7px;
    right: 0;
    top: 0;
    z-index: 1;
    border-right: 3px solid transparent;
  }

  .resize-handle:hover {
    border-color: #ccc;
  }

  .resize-handle.active {
    border-color: #517ea5;
  }
}

*:disabled {
  background-color: indigo;
}

.padding {
  td>span, td>a>span{
    padding: 2px;
    padding-left: 16px;
    padding-right: 16px;
    
  }
}
