* {
  box-sizing: border-box;
}

.roles-content {
  border: 0px solid blue;
  width:90%;
  //margin: 0 auto;
  >section {
    display: flex;
    direction: row;
    align-items: center;
    justify-content: space-between;
    
  }
  .acc-section{
    position: relative;
    goa-accordion {
      width: 100%;
      goa-table{
        td{
          padding-top: 1px;
          padding-bottom: 1px;
          //padding-left: 1px;
          display: flex;
          justify-content:space-between;
          align-items: center;
          span{
            //border:1px solid red;
            //height:100%;
            //padding: 2px;
            
          }
          
        }
        th{
          padding-top: 1px;
          padding-bottom: 1px;
          //padding-left: 1px;
          
        }
      } 
    }
    .acc-toolbar{
      position: absolute;
      z-index: 12;
      top: 6px;
      right: 0;
      display: flex;
      justify-content: flex-end;
      goa-button{
        margin: 0 4px;;
      }
      
    }
  }
  
  .granted-section {
  }

  .search-title-section {
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    .search-title {}
  }

  .search-section {

    margin-bottom: 40px;
    width: 100%;

    .search-wrap {
      position: relative;
      .search-buttons-wrap {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 70px;
        
        //goa-icon-button[icon="close"] {
          //visibility: hidden;
        //}
      }
      goa-input {
        width: 100%;
      }
    }
    .search-preview-wrap {
      border: 1px solid grey;
      position: absolute;
      z-index: 10000;
      background: #fff;
      height: auto;
      margin-bottom: 25px;
      border-radius: 6px;
      //visibility: hidden;
      list-style-type: none;
      margin: 0;
      padding: 0;
      box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
      overflow: auto;
      li span {
          border: 1px solid #ddd;
          margin-top: -1px; /* Prevent double borders */
          background-color: transparent;
          padding: 12px;
          text-decoration: none;
          font-size: 18px;
          color: black;
          display: block
        }
      li a:hover:not(.header) {
          background-color: #eee;
      }
    }
  }

  .all-roles-section {
    goa-accordion {
      width: 100%;
    }

    .table-section {
      margin-bottom: 40px;
      border: 1px solid gray;
    }
    .roles-all-buttons{
      display: flex;
      justify-content:flex-end;
      align-items: center;
    }
  }
  .disabled{
    pointer-events: none;
    cursor: not-allowed;
    td{
      opacity: 0.5;
    }
  }
  .center{
    margin: 0 auto;
    width:50%;
  }  
  .top-align{
    height: 100%;
  }
  .dt-with-error{
    div:nth-child(1)>div:nth-child(1){
      fieldset{
        border-radius: 4px;
        border: 2px solid red;
      }
    }
    .error-div{
      color: red;
      white-space: initial;
      text-overflow:initial;
    }
    .error-div::before{
      //content: "\26A0";
      //color: red;
      //font-size:large;
    }
  }
}